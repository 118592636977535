import { useState, useEffect } from 'react';
import { usePermissionValues } from './useCheckPermission';

export function useGetFirstPath() {
  const { 
    permissionVideos, permissionSeries, permissionLiveTV, permissionLibrary, permissionEncoding, 
    permissionVideoLayout, permissionAlbums, permissionPodcasts, permissionPlayist, 
    permissionAudioGenres, permissionAudioLayout, permissionCategories, permissionLanguage, 
    permissionGenres, permissionProdHouse, permissionCasts, permissionGeoRestriction, 
    permissionContentPartner, permissionAdminUsers, permissionRoles, permissionBroadcastGroups, 
    permissionBroadcastMessages, permissionContentAnalystics, permissionCustomerAnalystics, 
    permissionFeedbacks, permissionPlans, permissionTransaction, permissionDashboard, 
    permissionGeoFencing, permissionUserManagement, permissionPaymentGateway, permissionStorageSettings, permissionCoupon
  } = usePermissionValues();

  const permission_Content_Settings = (
    permissionCategories.isViewable || permissionLanguage.isViewable || permissionGenres.isViewable ||
    permissionProdHouse.isViewable || permissionCasts.isViewable || permissionGeoRestriction.isViewable || 
    permissionContentPartner.isViewable
  );

  const permission_Admin = (
    permissionAdminUsers.isViewable || permissionRoles.isViewable
  );

  const permission_Broadcast = (
    permissionBroadcastGroups.isViewable || permissionBroadcastMessages.isViewable
  );

  const [defaultPath, setDefaultPath] = useState(null);

  useEffect(() => {
    const getDefaultRoute = () => {
      if (permissionDashboard.isViewable) {
        return "/dashboard";
      }
      if (
        permissionVideos.isViewable || permissionSeries.isViewable || permissionLiveTV.isViewable ||
        permissionLibrary.isViewable || permissionEncoding.isViewable || permissionVideoLayout.isViewable
      ) {
        return "/content";
      }
      if (
        permissionAlbums.isViewable || permissionPodcasts.isViewable || permissionPlayist.isViewable ||
        permissionAudioGenres.isViewable || permissionAudioLayout.isViewable
      ) {
        return "/audio";
      }
      if (permissionPlans.isViewable || permissionTransaction.isViewable || 
         permissionPaymentGateway.isViewable || permissionCoupon.isViewable) {
        return "/monetization";
      }
      if (permission_Content_Settings) {
        return "/manage";
      }
      if (permission_Admin) {
        return "/manage/usermanagement";
      }
      if (permission_Broadcast) {
        return "/manage/notification";
      }
      if (permissionStorageSettings.isViewable) {
        return "/manage/storage";
      }
      if (permissionUserManagement.isViewable) {
        return "/manage/users";
      }
      if (permissionFeedbacks.isViewable) {
        return "/manage/feedback";
      }
      if (permissionGeoFencing.isViewable) {
        return "/manage/geofencing";
      }

      return "/dashboard";
    };

    if (permissionVideos.isViewable !== undefined) {
      const route = getDefaultRoute();
      setDefaultPath(route);
    }
  }, [
    permissionDashboard, permissionVideos, permissionSeries, permissionLiveTV, permissionLibrary, 
    permissionEncoding, permissionVideoLayout, permissionAlbums, permissionPodcasts, permissionPlayist, 
    permissionAudioGenres, permissionAudioLayout, permissionPlans, permissionTransaction, 
    permissionCategories, permissionLanguage, permissionGenres, permissionProdHouse, permissionCasts, 
    permissionGeoRestriction, permissionContentPartner, permissionAdminUsers, permissionRoles, 
    permissionBroadcastGroups, permissionBroadcastMessages, permissionUserManagement, 
    permissionFeedbacks, permissionGeoFencing, permissionStorageSettings, permissionPaymentGateway
  ]);

  return defaultPath;
}
