import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {PasswordReset} from './components/PasswordReset'
import { EmailLogin } from './components/EmailLogin'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<EmailLogin />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='password-reset' element={<PasswordReset />} />
      <Route index element={<EmailLogin />} />
    </Route>
  </Routes>
)

export {AuthPage}
