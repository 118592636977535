import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../helpers";
import { useEffect, useState } from "react";
import { usePermissionValues } from "../../../../app/commonComponents/useCheckPermission";
import { useAuth } from "../../../../app/modules/auth";

const SecondaryMenu = () => {

  const {currentUser} = useAuth();
  const {
    permissionCategories, permissionLanguage, permissionGenres, permissionProdHouse, permissionCasts, permissionGeoRestriction,
    permissionCustomerAnalystics, permissionContentAnalystics, permissionAudioAnalystics,
    permissionAdminUsers, permissionRoles, permissionBroadcastGroups, permissionBroadcastMessages,
    permissionPlans, permissionTransaction, permissionContentPartner
  } = usePermissionValues();

  const contentVideoMenus = [
    { title: "Entries", link: "content/video/entries" },
  ];

  const contentLiveStreamMenu = [];
  
  const contentPlaylistMenu = [
    { title: "Series", link: "content/playlist/series" },
    { title: "Season", link: "content/playlist/season" },
  ];
  
  const contentLibraryMenu = [
    { title: "Entries", link: "content/library/entries" },
  ];

  const contentMonetizationMenu = [
    { title: "Profile", link: "content/monetization/profile" },
    { title: "Plans", link: "content/monetization/plans" }
  ];

  const contentEncodingMenu = [
    { title: "Profile", link: "content/encoding/profile" },
    { title: "Presets", link: "content/encoding/presets" },
  ];

  const analyticsMenu = [
    ...(permissionContentAnalystics.isViewable ? [{ title: "Content", link: "analytics/analytics-content" }] : []),
    ...(permissionCustomerAnalystics.isViewable ? [{ title: "Customers", link: "analytics/audience" }] : []),
    ...(permissionContentAnalystics.isViewable ? [{ title: "Audio", link: "analytics/analytics-audio" }] : []),
    ...(permissionContentAnalystics.isViewable ? [{ title: "Devices", link: "analytics/analytics-devices" }] : []),
    ...(permissionContentAnalystics.isViewable ? [{ title: "Geo Location", link: "analytics/analytics-geolocation" }] : []),
    ...(permissionContentAnalystics.isViewable ? [{ title: "Real Time", link: "analytics/analytics-realtime" }] : []),
    ...(permissionContentAnalystics.isViewable ? [{ title: "Revenue", link: "analytics/analytics-revenue" }] : []),
  ];

  const notificationMenu = [
    ...(permissionBroadcastGroups.isViewable ? [{ title: "Groups", link: "manage/notification/groups" }] : []),
    ...(permissionBroadcastMessages.isViewable ? [{ title: "Messages", link: "manage/notification/messages" }] : []),
  ];

  const UserManagementMenu = [
    ...(permissionAdminUsers.isViewable ? [{ title: "Admins", link: "manage/usermanagement/admins" }] : []),
    ...(permissionRoles.isViewable ? [{ title: "Roles", link: "manage/usermanagement/roles" }] : []),
  ];

  const LayoutMenu = [
    { title: "General", link: "content/layout/general-layout" },
    { title: "Kids", link: "content/layout/kids-layout" },
    { title: "Sub Layout", link: "content/layout/sub-layout" },
    { title: "Kids Sub Layout", link: "content/layout/kids-sub-layout" },
  ];

  const MonetizationMenu = [
    ...(permissionPlans.isViewable ? [{ title: "Plans", link: "monetization/plans" }] : []),
    ...(permissionTransaction.isViewable ? [{ title: "Transactions", link: "monetization/transaction" }] : []),
  ];

  const ProfileSettingsMenu = [
    { title: "User Profile", link: "settings/profile-settings/user" },
    ...(currentUser?.userType === 'SUPERADMIN' ? [{ title: "Customer Profile", link: "settings/profile-settings/customer" }] : []),
  ];

  const contentSettingsMenu = [
    ...(permissionProdHouse.isViewable ? [{ title: "Production House", link: "/manage/setting-contents/production-company" }] : []),
    ...(permissionGenres.isViewable ? [{ title: "Genre", link: "/manage/setting-contents/genre" }] : []),
    ...(permissionCasts.isViewable ? [{ title: "Cast", link: "/manage/setting-contents/cast" }] : []),
    ...(permissionLanguage.isViewable ? [{ title: "Language", link: "/manage/setting-contents/language" }] : []),
    ...(permissionCategories.isViewable ? [{ title: "Category", link: "/manage/setting-contents/category" }] : []),
    ...(permissionCategories.isViewable ? [{ title: "Content Partner", link: "/manage/setting-contents/content-partner" }] : []),
    // ...(permissionContentPartner.isViewable ? [{ title: "Content Partner", link: "/manage/setting-contents/content-partner" }] : []),
    ...(permissionGeoRestriction.isViewable ? [{ title: "Geo Restrictions", link: "/manage/setting-contents/georestriction" }] : []),
  ];

  const { pathname } = useLocation();

  interface Menu {
    title: string;
    link: string;
  }
  const [secondaryMenu, setSecondaryMenu] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      permissionContentAnalystics &&
      permissionCustomerAnalystics &&
      permissionBroadcastGroups &&
      permissionBroadcastMessages &&
      permissionAdminUsers &&
      permissionRoles &&
      permissionPlans &&
      permissionTransaction &&
      permissionProdHouse &&
      permissionGenres &&
      permissionCasts &&
      permissionLanguage &&
      permissionCategories &&
      permissionGeoRestriction
    ) {
      setLoading(false); 


      if (pathname.includes("/content/livestream")) {
        setSecondaryMenu(contentLiveStreamMenu);
      } else if (pathname.includes("/content/encoding")) {
        // setSecondaryMenu(contentEncodingMenu);
      } else if (pathname.includes("content/monetization") && !pathname.includes("/analytics")) {
        setSecondaryMenu(contentMonetizationMenu);
      } else if (pathname.includes("/analytics")) {
        // setSecondaryMenu(analyticsMenu);
      } else if (pathname.includes("/notification")) {
        // setSecondaryMenu(notificationMenu);
      } else if (pathname.includes("/usermanagement")) {
        // setSecondaryMenu(UserManagementMenu);
      } else if (pathname.includes("/monetization")) {
        // setSecondaryMenu(MonetizationMenu);
      } else if (pathname.includes("content/layout")) {
        // setSecondaryMenu(LayoutMenu);
      } else if (pathname.includes("/setting-contents")) {
        // setSecondaryMenu(contentSettingsMenu);
      } else if (pathname.includes("settings/profile-settings")) {
        setSecondaryMenu(ProfileSettingsMenu);  
      } else {
        setSecondaryMenu([]);
      }
    }
  }, [
    pathname,
    permissionContentAnalystics,
    permissionCustomerAnalystics,
    permissionBroadcastGroups,
    permissionBroadcastMessages,
    permissionAdminUsers,
    permissionRoles,
    permissionPlans,
    permissionTransaction,
    permissionProdHouse,
    permissionGenres,
    permissionCasts,
    permissionLanguage,
    permissionCategories,
    permissionGeoRestriction
  ]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      id="#kt_app_header_secondary_menu"
      style={{
        backgroundColor: "#ffffff",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 10px 30px 0px rgba(82, 63, 105, 0.05)",
      }}
    >
      <div className="app-container container">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          {secondaryMenu.length > 0 &&
            secondaryMenu.map((item) => (
              <li
                className="nav-item"
                key={item.title}
                style={{ fontWeight: "500" }}
              >
                <Link
                  to={item.link}
                  className={clsx(
                    `nav-link text-active-primary link-dark py-3 me-6`,
                    {
                      active: checkIsActive(pathname, item.link),
                    }
                  )}
                >
                  {item.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export { SecondaryMenu };
