/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import { useLayout } from "../../../core";
import { usePermissionValues } from "../../../../../app/commonComponents/useCheckPermission";
import { useLocation } from "react-router";
import clsx from "clsx";
import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";

const MegaAudioDash: FC = () => {
  const { setLayoutType, setToolbarType } = useLayout();
  const { pathname } = useLocation();
  const {
    permissionAlbums, permissionPodcasts, permissionPlayist, permissionAudioGenres, permissionAudioLayout,
    permissionCustomerAnalystics, permissionAudioAnalystics, permissionGeoFencing,
    permissionDeviceAnalystics, permissionGeoLocationAnalystics, permissionRealTimeAnalystics,
    permissionRevenueAnalystics } = usePermissionValues();

  const Permission_Analytics =  permissionCustomerAnalystics.isViewable || permissionAudioAnalystics.isViewable ||
                                permissionDeviceAnalystics.isViewable || permissionGeoLocationAnalystics.isViewable || 
                                permissionRealTimeAnalystics.isViewable || permissionRevenueAnalystics.isViewable;

  return (
      <div
        className="menu-state-bg menu-extended overflow-hidden overflow-lg-visible"
        data-kt-menu-dismiss="true"
      >
        <div className="row">
        <div className={`col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6`}>
        {/* <div className={`${Permission_Analytics ? `col-lg-8`: `col-lg-12`} mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6`}> */}
            <div className="row">
              {permissionAlbums.isViewable && (
                <div className="col-lg-6 mb-3">
                  <div className="menu-item p-0 m-0">
                    <Link
                      to="/audio/albums/entries"
                      className={clsx("menu-link ", {
                        active: checkIsActive(
                          pathname,
                          "/audio/albums/entries"
                        ),
                      })}
                    >
                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-abstract-27 text-blue-primary fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </i>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">
                          Albums
                        </span>
                        <span className="fs-7 fw-semibold text-muted">
                        Organize Albums
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {permissionPodcasts.isViewable && (
                <div className="col-lg-6 mb-3">
                  <div className="menu-item p-0 m-0">
                    <Link
                      to="/audio/podcast/entries"
                      className={clsx("menu-link ", {
                        active: checkIsActive(
                          pathname,
                          "/audio/podcast/entries"
                        ),
                      })}
                    >
                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-speaker text-danger fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </i>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">
                          Podcast
                        </span>
                        <span className="fs-7 fw-semibold text-muted">
                        Stream Episodes
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {permissionPlayist.isViewable && (
                <div className="col-lg-6 mb-3">
                  <div className="menu-item p-0 m-0">
                    <Link
                      to="/audio/playlist/entries"
                      className={clsx("menu-link ", {
                        active: checkIsActive(
                          pathname,
                          "/audio/playlist/entries"
                        ),
                      })}
                    >
                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-abstract-44 text-info fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">
                          Playlist
                        </span>
                        <span className="fs-7 fw-semibold text-muted">
                        Create Collections
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {permissionAudioGenres.isViewable && (
                <div className="col-lg-6 mb-3">
                  <div className="menu-item p-0 m-0">
                    <Link
                      to="/audio/genre/entries"
                      className={clsx("menu-link ", {
                        active: checkIsActive(pathname, "/audio/genre/entries"),
                      })}
                    >
                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-color-swatch text-blue-primary fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                          <span className="path5"></span>
                          <span className="path6"></span>
                          <span className="path7"></span>
                          <span className="path8"></span>
                          <span className="path9"></span>
                          <span className="path10"></span>
                          <span className="path11"></span>
                          <span className="path12"></span>
                          <span className="path13"></span>
                          <span className="path14"></span>
                          <span className="path15"></span>
                          <span className="path16"></span>
                          <span className="path17"></span>
                          <span className="path18"></span>
                          <span className="path19"></span>
                          <span className="path20"></span>
                          <span className="path21"></span>
                        </i>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">
                          Genre
                        </span>
                        <span className="fs-7 fw-semibold text-muted">
                        Explore Categories
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              )}

              {permissionAudioLayout.isViewable && (
                <div className="col-lg-6 mb-3">
                  <div className="menu-item p-0 m-0">
                    <Link
                      to="/audio/layout/entries"
                      className={clsx("menu-link ", {
                        active: checkIsActive(
                          pathname,
                          "/audio/layout/entries"
                        ),
                      })}
                    >
                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                        <i className="ki-duotone ki-switch text-warning fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fs-6 fw-bold text-gray-800">
                          Layout
                        </span>
                        <span className="fs-7 fw-semibold text-muted">
                        Customize View
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              )}
            </div>

          {(permissionGeoFencing.isViewable) && (
          <>
          <div className="separator separator-dashed mx-5 my-5"></div>

          <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
          <div className="d-flex flex-column me-5">
            <div className="fs-6 fw-bold text-gray-800">
            App Settings
            </div>
            <div className="fs-7 fw-semibold text-muted">
            Manage Geolocation Settings with Ease
            </div>
          </div>
          <Link
            to="/manage/geofencing"
            className="btn btn-sm btn-secondary fw-bold"
            style={{backgroundColor:'#3699ff', color:'#fff'}}
          >
            Explore
          </Link>
          </div>
          </>)}

          </div>

          {/* {(Permission_Analytics) && (
          <div className="menu-more bg-light col-lg-4 py-3 px-3 py-lg-6 px-lg-6 rounded-end">
            <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
              Analytics
            </h4>

            {(permissionAudioAnalystics.isViewable) &&
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-audio"
                className={clsx("menu-link py-2", {
                  active: checkIsActive(pathname, "/analytics/analytics-audio"),
                })}
              >
                <span className="menu-title">Audio Analytics</span>
              </Link>
            </div>}

            {(permissionCustomerAnalystics.isViewable) &&
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/audience"
                className={clsx("menu-link py-2", {
                  active: checkIsActive(pathname, "/analytics/audience"),
                })}
              >
                <span className="menu-title">Customer Analytics</span>
              </Link>
            </div>}

            {(permissionDeviceAnalystics.isViewable) &&
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-devices"
                className={clsx("menu-link py-2", {
                  active: checkIsActive(
                    pathname,
                    "/analytics/analytics-devices"
                  ),
                })}
              >
                <span className="menu-title">Devices</span>
              </Link>
            </div>}

            {(permissionGeoLocationAnalystics.isViewable) &&
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-geolocation"
                className={clsx("menu-link py-2", {
                  active: checkIsActive(
                    pathname,
                    "/analytics/analytics-geolocation"
                  ),
                })}
              >
                <span className="menu-title">Geo Location</span>
              </Link>
            </div>}

            {(permissionRealTimeAnalystics.isViewable) &&
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-realtime"
                className={clsx("menu-link py-2", {
                  active: checkIsActive(
                    pathname,
                    "/analytics/analytics-realtime"
                  ),
                })}
              >
                <span className="menu-title">Real Time</span>
              </Link>
            </div>}

            {(permissionRevenueAnalystics.isViewable) &&
            <div className="menu-item p-0 m-0">
              <Link
                to="/analytics/analytics-revenue"
                className={clsx("menu-link py-2", {
                  active: checkIsActive(
                    pathname,
                    "/analytics/analytics-revenue"
                  ),
                })}
              >
                <span className="menu-title">Revenue</span>
              </Link>
            </div>}

          </div>
          )} */}
        </div>
      </div>
  );
};

export { MegaAudioDash };
